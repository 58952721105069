import autoComplete from '@tarekraafat/autocomplete.js/dist/js/autoComplete';
import '@tarekraafat/autocomplete.js/dist/css/autoComplete.css';

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('input#autoComplete') !== null) {
    const addresCompleter = new autoComplete({
      data: {
        src: async () => {
          const searchVal = encodeURIComponent(document.querySelector('input#autoComplete').value);
          const rawResult = await fetch(`https://search-api-production.herokuapp.com/search?q=${searchVal}&helpers[]=geosearch-v2&helpers[]=bbl`);
          const parsedResult = await rawResult.json();
          return parsedResult;
        },
        key: ['label']
      },
      threshold: 4,
      onSelection: selected => {
        const selectionObject = selected.selection.value;
        const hiddenBuilding = document.querySelector('input#building_code');
        const autoComplete = document.querySelector('input#autoComplete');
        hiddenBuilding.value = selectionObject.bbl;
        autoComplete.value = selectionObject.label;
      },
    });
  }
});
